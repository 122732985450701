<template>
  <div>
    <b-container class="mt-5">
      <b-card
        class="text-center"
        title="Rules for 2nd &quot;Sail from Hangzhou&quot; Live Channel Theory Exam"
        :sub-title="`Exam Time：${examTime.begin} -- ${examTime.end} (GMT+8)`"
        footer="This exam is conducted by the Organizing Committee of Super StarT. Please read the above rules carefully.
                If you have any questions, please consult your course group admin or teacher."
        footer-tag="footer"
      >
        <b-card-text>
          <ol class="mt-3 pl-3 text-left exam-card-text">
            <li>Exam Duration: 60 minutes (You are not allowed to submit your paper within the first 20 minutes.)</li>
            <li>Passing Marks: 60<b> (Attention! You cannot retake the exam once you pass.)</b></li>
            <li>
              This exam paper contains three questions types: true-or-false question (2.5 Marks),multiple-choice question
              (2.5 Marks), and multiple-answer question (5 Marks for selecting all correct answers, 2.5 Marks for selecting
              some correct answers, no marks for selecting a wrong answer).
            </li>
            <li>Do not refresh this page when doing the exam, or you will lose your progress.</li>
            <li>If the "Start Exam" button is not working, please refresh the page.</li>
            <li>If you fail the exam, you can still retake it within Exam Time.</li>
          </ol>
        </b-card-text>
        <b-row class="text-center">
          <b-col>
            <el-button
              v-if="examTime.between && !isPass"
              type="primary"
              @click="gotoExam"
            >
              Start Exam
            </el-button>
            <el-button v-else-if="examTime.between && isPass" disabled>You have passed the exam!</el-button>
            <el-button v-else disabled>Not in Exam Time</el-button>
          </b-col>
        </b-row>
      </b-card>
      <div>
        <div class="mt-5 mb-3 text-center">
          <span class="h4">
            Exam Records
            <b-link v-if="isPass" class="h6" @click="downloadCert">Download Statement of Accomplishment</b-link>
          </span>
        </div>
        <b-table
          :items="tableData.examHistory"
          :fields="tableData.fields"
          :busy="tableData.isBusy"
          outlined
          class="bg-white"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(No)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(Scores)="data">
            <el-tag v-if="parseFloat(data.value) >= 60" type="success" class="text-tag">{{ data.value }}</el-tag>
            <el-tag v-else type="info" class="text-tag">{{ data.value }}</el-tag>
          </template>
        </b-table>
      </div>
    </b-container>
  </div>
</template>

<script>
import { getHistory, getInfo, getCert } from '@/api/exam'

export default {
  name: 'Home',
  data() {
    return {
      isPass: false,
      tableData: {
        fields: ['No', 'Date', 'Scores'],
        examHistory: [],
        isBusy: false
      },
      /**
       * 与考试相关的时间参数
       */
      examTime: {
        begin: '', // 开始时间
        end: '', // 结束时间
        limit: null, // 剩余时间
        between: false
      }
    }
  },
  created() {
    this.getHistory()
    this.getExamInfo()
  },
  methods: {
    /**
     * 获取与考试相关的信息
     * 包括考试开始结束时间，当前服务器时间，当前是否处于考试期间
     */
    getExamInfo() {
      getInfo().then(e => {
        const data = e.data
        this.examTime.begin = data['examBeginTime']
        this.examTime.end = data['examEndTime']
        this.currentTime = data['currentTime']
        this.examTime.between = Boolean(data['betweenExamTime'])
        this.startCountDown = true
      })
        .catch(() => {})
    },
    /**
     * 获取用户的考试记录
     * 包含考题提交时间，分数，证书地址
     */
    getHistory() {
      let date = ''
      this.tableData.isBusy = true
      getHistory().then(e => {
        e.data.history.forEach(item => {
          date = item.date
          this.tableData.examHistory.push({
            'Date': date,
            'Scores': item['scores'],
            'Cert': ''
          })
        })
        this.tableData.isBusy = false
        this.isPass = e.data.isPass
      })
        .catch(err => {
          this.tableData.isBusy = false
          if (err.code === 401) {
            this.$router.push('/login')
          }
        })
    },
    /**
     * 跳转到考试页面
     */
    gotoExam() {
      this.$router.push({
        path: '/exam/t'
      })
    },
    downloadCert() {
      this.$loading({ text: 'downloading' })
      getCert().then(e => {
        this.downloadByBlob(e.data, e.data.split('/').pop())
      })
        .catch(() => {
          this.$loading({ text: 'downloading' }).close()
        })
    },
    downloadByBlob(url, name) {
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          this.download(url, name)
          // 用完释放URL对象
          URL.revokeObjectURL(url)
          this.$loading({ text: 'downloading' }).close()
        })
      }
    },
    download(href, name) {
      const eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    }
  }
}
</script>
